import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import emailjs from '@emailjs/browser';
import Aos from "aos";
import "aos/dist/aos.css";
export default function ContactUs() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    // Validate Name
    if (!formData.name) {
      formIsValid = false;
      errors.name = "Please enter your name.";
    }

    // Validate Email
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!formData.email) {
      formIsValid = false;
      errors.email = "Please enter your email.";
    } else if (!emailRegex.test(formData.email)) {
      formIsValid = false;
      errors.email = "Please enter a valid email address.";
    }

    // Validate Message
    if (!formData.message) {
      formIsValid = false;
      errors.message = "Please enter your message.";
    }

    setFormErrors(errors);
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    // Use EmailJS to send the form data
    emailjs
      .send(
        "service_yfnaxp3", // Replace with your EmailJS service ID
        "template_cf7bmts", // Replace with your EmailJS template ID
        formData, // Form data to send
        "H1g6T4x3H_lso0yTR" // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Your message has been sent successfully!");
          setFormData({ name: "", email: "", message: "" }); 
        },
        (error) => {
          console.log("FAILED...", error);
          alert("There was an error sending your message. Please try again.");
        }
      );
  };

  
  return (
    <>
      <div className="aboutUsBannerImg">
        <div className="container">
          <div className="contactUs">
            <Row>
              <Col xl={6} md={6} lg={6} sm={12}>
                <div className="titleFont">
                  <h3>Contact Us</h3>
                  <h2>Ready to Get Started?</h2>
                </div>
                <form className="row g-3 needs-validation formContactus" onSubmit={handleSubmit}>
      <div className="col-md-6 col-sm-6 col-6">
        <input
          type="text"
          className={`form-control ${formErrors.name ? "is-invalid" : ""}`}
          id="validationCustom01"
          name="name"
          placeholder="Name*"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <div className="invalid-feedback">{formErrors.name}</div>
      </div>

      <div className="col-md-6 col-sm-6 col-6">
        <input
          type="email"
          className={`form-control ${formErrors.email ? "is-invalid" : ""}`}
          id="validationCustom02"
          name="email"
          placeholder="E-mail*"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <div className="invalid-feedback">{formErrors.email}</div>
      </div>

      <div className="col-md-12">
        <textarea
          className={`form-control ${formErrors.message ? "is-invalid" : ""}`}
          id="validationCustom04"
          name="message"
          placeholder="Send Message*"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <div className="invalid-feedback">{formErrors.message}</div>
      </div>

      <div className="col-12">
      <button type="submit" className="ourSurvice">Get Started </button>
      </div>
    </form>
              </Col>
              <Col xl={6} md={6} lg={6} sm={12}>
                <div className="quickContact">
                  <h2>Quick Contact</h2>
                  <p>
                    If you're interested in exploring affordable and flexible
                    educational opportunities, we encourage you to reach out to
                    Truckmart Africa LTD.
                  </p>
                  <div className="contactUsIconContain">
                    <i className="fa fa-map-marker"></i>
                    <span>Maasai Rd, Next to Libra House P . O. Box</span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fa fa-map-marker"></i>
                    <span>974 – 00100, GPO, Nairobi, Kenya</span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-phone-alt"></i>
                    <span>+254 709 278 000</span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-envelope"></i>
                    <span>sales@truckmartafrica.com</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
