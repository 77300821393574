import React, { useState, useEffect } from "react";
// import "./TruckmartAds.css"; // Add your custom styles here if needed

// Sample ad data (You can add more ads here)
const adsData = [
  {
    title: "Truck Sales",
    description:
      "Get the best deals on new and used trucks at Truckmart. Reliable and affordable!",
    image: "/assets/images/ad1.jpg", // Add image paths or URLs
  },
  {
    title: "Garage Repairs",
    description:
      "Need repairs? Our expert technicians ensure your trucks are road-ready.",
    image: "/assets/images/ad2.jpg",
  },
  {
    title: "Spare Parts",
    description:
      "Top-quality spare parts for all makes and models at competitive prices.",
    image: "/assets/images/ad3.jpg",
  },
];

const TruckmartAds = () => {
  const [currentAd, setCurrentAd] = useState(0);

  // Optionally rotate ads every 5 seconds
  useEffect(() => {
    const adRotation = setInterval(() => {
      setCurrentAd((prevAd) => (prevAd + 1) % adsData.length);
    }, 5000); // Change ad every 5 seconds

    return () => clearInterval(adRotation);
  }, []);

  return (
    <div className="truckmart-ad-container">
      <div className="truckmart-ad">
        <img
          src={adsData[currentAd].image}
          alt={adsData[currentAd].title}
          className="ad-image"
        />
        <div className="ad-content">
          <h2>{adsData[currentAd].title}</h2>
          <p>{adsData[currentAd].description}</p>
        </div>
      </div>
    </div>
  );
};

export default TruckmartAds;
